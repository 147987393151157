import { MassageDatabase } from './massages'

export enum Weekday {
  Monday = 'Montag',
  Tuesday = 'Dienstag',
  Wednesday = 'Mittwoch',
  Thursday = 'Donnerstag',
  Friday = 'Freitag',
  Saturday = 'Samstag',
  Sunday = 'Sonntag',
}

export const Weekdays = Object.values(Weekday)

export const Texts = {
  Quote: [
    'Die stärkende Kraft achtsamer Berührung',
    'in weiblicher Verbundenheit.'
  ],
  Disclaimer:
    'Ich bin keine Ärztin oder Heilpraktikerin und meine angebotenen Leistungen ersetzen keine ärztliche Diagnose und Behandlung. Ich arbeite präventiv.',
  Welcome: {
    Title: 'Herzlich Willkommen',
    TextStart:
      'Sisterhood Massagen bietet jeder Frau einen achtsamen, geschützten Raum zur Entspannung und Selbstfürsorge: von Frau zu Frau mit Händen, die geben, ohne etwas zu wollen.',
    Questions: [
      'Bist du schwanger und möchtest sicher, einfühlsam und dennoch effektiv massiert werden?',
      'Du wünscht dir eine herrliche Auszeit zum Entspannen und Lösen von Verspannungen und Blockaden?',
      'Schmerzt dein Rücken z.B. durch das Tragen und Stillen deiner Kinder und du wünschst dir gezielte Griffe, die dir wirklich Abhilfe schaffen? ⁠',
      'Leidest du unter hormonellen Dysbalancen und suchst nach einer begleitenden Unterstützung in Form von Massagen?'
    ],
    TextEnd:
      'Ich biete dir eine professionelle achtsame Massage ganz nach deinen aktuellen Bedürfnissen.'
  },
  Promotion: {
    Title: 'Hi Sister!',
    Image: 'voucher-512px',
    Alt: 'Eine Frau reicht einer anderen Frau eine Blume.',
    Text: [
      { text: 'Ich biete jeder ', highlight: false },
      { text: 'Neukundin', highlight: true },
      { text: ' einen ', highlight: false },
      { text: 'Rabatt von 10,- Euro', highlight: true },
      {
        text: ' auf ihre erste Massage bei mir. Schreibe einfach “Hi Sister!” in das Kommentarfeld in meinem Buchungstool. Ich freue mich auf dich!',
        highlight: false
      }
    ]
  },
  Massages: {
    Title: 'Meine Leistungen',
    DetailImages: [
      {
        name: 'Rueckenmassage-640px',
        alt: 'Zwei Hände massieren den Rücken einer Frau.'
      },
      {
        name: 'Kopfmassage-640px',
        alt: 'Zwei Hände massieren den Kopf einer Frau.'
      },
      {
        name: 'Seitenlage-640px',
        alt: 'Zwei Hände massieren den Rücken einer Frau in Seitenlage.'
      }
    ],
    CompleteImages: [
      {
        name: 'Wellnessmassage-640px',
        alt: 'Eine Kunden wird von mir am Rücken massiert.'
      },
      {
        name: 'Schwangerschaftsmassage-640px',
        alt: 'Eine schwangere Frau in Seitenlage wird von mir massiert.'
      },
      {
        name: 'Postnatalemassage-640px',
        alt: 'Eine Frau wird von mir in Seitenlage and der Schulter massiert.'
      }
    ],
    Offer: MassageDatabase
  },
  OpeningHours: {
    Title: 'Meine Praxiszeiten',
    Hours: {
      [Weekday.Monday]: '18:00 - 21:00',
      [Weekday.Tuesday]: '10:00 - 13:00',
      [Weekday.Wednesday]: '-',
      [Weekday.Thursday]: '16:00 - 19:00',
      [Weekday.Friday]: '16:00 - 21:00',
      [Weekday.Saturday]: '09:30 - 19:30',
      [Weekday.Sunday]: '-'
    },
    HintStart:
      'Wenn du akute Schmerzen hast, oder nur zu ganz bestimmten Zeiten kannst, melde dich gerne ',
    HintLink: 'direkt bei mir',
    HintEnd: '. Wir finden eine Lösung.'
  },
  Rooms: {
    Title: 'Meine Räumlichkeiten',
    Images: [
      {
        name: 'room2-640px',
        alt: 'Der Empfangstisch der Hebammenpraxis mit einem Bilderrahmen auf dem "Hebammenpraxis in Bargteheide" steht und die Siluette eines Storches mit einem Bündel im Schnabel abgebildet ist.'
      },
      { name: 'room1-640px', alt: 'Ein hell erleuchteter Raum mit Tisch.' },
      {
        name: 'room3-640px',
        alt: 'Eine Massageliege mit einer roten Decke und einem Herz-Wärmekissen.'
      }
    ]
  },
  Location: {
    Title: 'Hier findest du mich',
    Map: {
      Alt: 'GoogleMaps Karte, die aus der Vogelperspektive zeigt, dass man über die Kreuzung geradeaus auf den Parkplatz fahren muss und am Ende links die Hebammenpraxis findet.',
      Caption:
        'Mein Praxisraum befindet sich in der Hebammenpraxis in Bargteheide in der Heinrich-Hertz-Straße 23a.'
    },
    Driveway: {
      Alt: 'Das Bild zeigt die Einfahrt zum Parkplatz, welcher direkt von der Kreuzung geradeaus zu erreichen ist.',
      Caption:
        'Du findest mich am Ende dieses Weges. Umso holpriger, umso richtiger ;)'
    },
    Address: {
      Title: 'Adresse',
      Name: 'Hebammenpraxis in Bargteheide',
      Street: 'Heinrich-Hertz-Straße 23a',
      City: 'Bargteheide',
      PostalCode: '22941',
      GoogleMaps: 'https://maps.app.goo.gl/WEwknsGG394VDrnG9'
    },
    Benefits: {
      Title: 'Entspannt ankommen'
    }
  },
  Contact: {
    Title: 'Kontakt',
    ContactTitle: 'Sisterhood Massagen',
    Name: 'Isabelle Petry',
    Mobile: '0176 61 31 95 73',
    Email: 'info@sisterhoodmassagen.de',
    ChannelsTitle: 'Online Präsenz',
    Channels: [
      {
        name: 'WhatsApp',
        qrcode: 'whatsapp.svg',
        subtitle: '0176 61 31 95 73',
        link: 'https://wa.me/4917661319573'
      },
      {
        name: 'Instagram',
        qrcode: 'instagram.svg',
        subtitle: '@sisterhoodmassagen',
        link: 'https://www.instagram.com/sisterhoodmassagen'
      }
    ]
  },
  AboutMe: {
    Header: {
      Title: 'Hallo,',
      Subtitle: 'ich bin Isabelle. Schön, dass du da bist.',
      Image: 'header-1280px',
      Alt: ''
    },
    Introduction: {
      Title1: 'Die stärkende Kraft achtsamer Berührung',
      Title2: 'in weiblicher Verbundenheit...',
      Image: 'introduction-452px',
      Alt: 'Ich gebe einer Kundin eine Rückenmassage.',
      Content: `... in die Welt zu tragen, ist meine Herzensaufgabe und das Motto von Sisterhood Massagen. Die Kraft dieser besonderen Unterstützung durfte ich schon in den verschiedensten Phasen meines Lebens erfahren und möchte diese für dich - von Frau zu Frau -  mit Händen, die geben, ohne etwas zu wollen, erlebbar machen. Hierfür schaffe ich einen sicheren, weiblichen und schamfreien Raum der Achtsamkeit und Fürsorge.
Mit dieser Vision habe ich Sisterhood Massagen Ende 2023 gegründet und begleite seither Frauen mit professioneller Körperarbeit in den verschiedensten Phasen ihres Lebens. Ich bin studierte Psychologin und ausgebildete Massagetherapeutin und mein besonderes Interesse gilt der untrennbaren Wechselwirkung zwischen Körper und Geist. Zudem glaube ich an das lebenslange Lernen und bilde mich laufend in den Bereichen Massage, Körpertherapie und Frauenheilkunde fort. 
Es ist lange bekannt, dass den Geschlechtern auf körperlicher und emotionaler Ebene unterschiedliche Ansprachen gut tun. Physische Unterschiede zum Mann sowie physische und hormonelle Veränderungen über den Lebenszyklus hinweg bringen für Frauen besondere Herausforderungen und Bedürfnisse mit sich. Egal ob Menstruation, Kinderwunsch, Schwangerschaft, Wochenbett oder die Wechseljahre, Massagen, die auf die natürlichen Bedürfnisse der Frau abgestimmt sind, können eine wertvolle Unterstützung sein, Beschwerden zu lindern, Balancen zu schaffen, Entspannung zu fördern und das allgemeine Wohlbefinden zu steigern. Dies ist der Kern meiner Arbeit.
Aufgewachsen bin ich in Bad Segeberg und nach einem kurzen Abstecher in Flensburg und ein paar Jahren in Hamburg habe ich 8 bunte und prägende Jahre in Großbritannien verbracht. Der Wunsch nach Wurzeln und mehr Verbundenheit hat mich wieder in die Heimat gezogen und seit 2022 lebe ich mit meinem Mann und unserem Sohn in Bargteheide. Neben der Körperarbeit sind meine Leidenschaften das Tanzen, die Natur, Yoga und gutes Essen.`
    },
    Qualifications: {
      Title: 'Meine Qualifikationen',
      Image: 'isabelle-600px',
      Alt: '',
      Education: {
        Title: 'Ausbildungen',
        Items: ['Master in Psychologie', 'Massagetherapeutin']
      },
      AdvancedEducation: {
        Title: 'Fortbildungen',
        Items: [
          'Schwangerschaftsmassage (InTouch)',
          'Therapeutische Frauenmassage',
          '1. Reiki-Grad (Romy Sauer)',
          'Schwangerschaftsmassage nach Romy Sauer',
          'Präventive Lymphdrainage (InTouch)',
          'Massage der Rhomboiden in Seitenlage'
        ]
      }
    }
  }
}
